





































































import Vue from 'vue';
import CreateHotelClassificationRequestViewModel from '@/src/services/viewModel/resource/CreateHotelClassificationRequestViewModel';

export default Vue.extend({
  hasBackButton: true,
  auth: true,
  props: {
    value: { type: Object, default: undefined },
  },
  data: () => ({
  }),
  computed: {
    languageItems(): {caption: string, id: string}[] {
      return [
        {
          caption: this.$t('page.createMembership.select.german'),
          id: 'de',
        },
        {
          caption: this.$t('page.createMembership.select.french'),
          id: 'fr',
        },
        {
          caption: this.$t('page.createMembership.select.italian'),
          id: 'it',
        },
      ];
    },
    currentLanguage(): string {
      return this.$translation.get().toLowerCase();
    },
    innerValue: {
      get(): CreateHotelClassificationRequestViewModel {
        return this.value ?? {};
      },
      set(value: CreateHotelClassificationRequestViewModel) {
        this.$emit('input', value);
      },
    },
  },
  async mounted() {
    const user = await this.$auth.getUser();
    this.innerValue.businessContact.address.eMail = user?.email;
  },
});
